import styled from "styled-components";
import Img from "gatsby-image";
export const AvatarOuter = styled.div`
  width: 75px;
  height: 75px;
  position: relative;
  margin: 0px;
  margin-bottom: 16px;
  float: left;
  margin-right: 16px;
`;
export const StyledImage = styled(Img)`
  width: 75px;
  height: 75px;
  padding: 0px;
  border-radius: 50%;
  vertical-align: middle;
  display: block !important;
  padding: 25px;
  border: 1px solid #eee;
  float: left;
`;

export const NameProfile = styled.div`
  float: right;
`;

export const AvatarBorder = styled.svg`
  width: 75px;
  height: 120px;
  view-box: 0 0 75 120;
  left: 0px;
  top: -7px;
  position: absolute;
  fill: #ddd;
  vertical-align: middle;
  display: none;
`;
