import React from "react";

import { StyledImage, AvatarBorder, AvatarOuter } from "./styled.js";
const Avatar = props => {
  return (
    <AvatarOuter>
      <AvatarBorder>
        <path d="M1.67 41.33C13.2 17.83 36.78 1.75 64 1.75V.25C36.17.25 12.07 16.7.33 40.67l1.34.66zM64 1.75c27.22 0 50.81 16.09 62.33 39.58l1.34-.66C115.93 16.7 91.83.25 64 .25v1.5zm62.33 100.92c-11.52 23.5-35.11 39.58-62.33 39.58v1.5c27.83 0 51.93-16.45 63.67-40.42l-1.34-.66zM64 142.25c-27.22 0-50.81-16.09-62.33-39.58l-1.34.66C12.07 127.3 36.17 143.75 64 143.75v-1.5z" />
      </AvatarBorder>
      <StyledImage fixed={props.src} />
    </AvatarOuter>
  );
};
export default Avatar;
