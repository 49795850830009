import React, { Component } from "react";
import { navigate } from "gatsby";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormValidator from "../helpers/FormValidator";
import validations from "./validations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/css/font-awesome.min.css";
import { setMinutes, setHours, addDays, subDays, format } from "date-fns";

var FontAwesome = require("react-fontawesome");
const axios = require("axios");

class CardForm extends Component {
  constructor() {
    super();
    this.validator = new FormValidator(validations.login_rules);
    var day1 = addDays(new Date(), 1);
    day1 = day1.setHours(11, 0, 0);

    var day2 = addDays(new Date(), 2);
    day2 = day2.setHours(13, 0, 0);

    var day3 = addDays(new Date(), 3);
    day3 = day3.setHours(15, 30, 0);

    this.state = {
      name: "",
      email: "",
      phone: "",
      project_details: "",
      num_hours: "1",
      validation: this.validator.valid(),
      startDate1: day1,
      startDate2: day2,
      startDate3: day3,
      stripeErr: "test"
    };

    this.submitted = false;
  }

  handleInputChange = event => {
    console.log(event.target.id);
    event.preventDefault();
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleSubmit = evt => {
    evt.preventDefault();
    this.setState({ submitting: true });
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      this.handleResult();
    } else {
      this.setState({ submitting: false });
    }
  };

  handleDateChange1 = date => {
    this.setState({ startDate1: date });
  };

  handleDateChange2 = date => {
    this.setState({ startDate2: date });
  };

  handleDateChange3 = date => {
    this.setState({ startDate3: date });
  };

  handleResult = token => {
    var scheduledDate1 = format(
      new Date(this.state.startDate1),
      "Do MMMM YYYY - HH:mm"
    );
    var scheduledDate2 = format(
      new Date(this.state.startDate2),
      "Do MMMM YYYY - HH:mm"
    );
    var scheduledDate3 = format(
      new Date(this.state.startDate3),
      "Do MMMM YYYY - HH:mm"
    );

    var self = this;

    var html =
      "You have a new chemistry session enquiry:<br><br>" +
      "From: " +
      self.state.name +
      "<br>" +
      "Email: " +
      self.state.email +
      "<br>" +
      "Phone: " +
      self.state.phone +
      "<br><hr>" +
      "Project Details: " +
      self.state.project_details +
      "<br><hr>" +
      "Suggested Dates 1: " +
      scheduledDate1 +
      "<br>" +
      "Suggested Dates 2: " +
      scheduledDate2 +
      "<br>" +
      "Suggested Dates 3: " +
      scheduledDate3;
    let data = {
      amount: 0,
      consultantName: this.props.consultant.consultantName,
      customerName: this.state.name,
      customerEmail: this.state.email,
      customerPhone: this.state.phone,
      projectDetails: this.state.project_details,

      scheduledDate1: scheduledDate1,
      scheduledDate2: scheduledDate2,
      scheduledDate3: scheduledDate3
    };
    self.data = data;
    axios
      .post("/.netlify/functions/send-email", {
        to: "chris@wpblade.com",
        from: "chris@indieridge.com",
        subject: "Consultant Enquiry",
        text: "mytext",
        html: html
      })
      .then(function(response) {
        navigate(
          "/chemistry-session-confirmation/",
          {
            state: {
              values: self.data
            }
          },
          { replace: true }
        );
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation; // otherwise just use what's in state

    return (
      <div className="CardDemo_xx">
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <div className="section white" style={{ padding: "0px" }}>
            <h3>Call Info</h3>
            <Form.Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="name">
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First / Last Name"
                    onChange={this.handleInputChange}
                    className={
                      validation.name.message
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  <span className="error" role="alert">
                    {validation.name.message}
                  </span>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email address*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="you@example.com"
                    onChange={this.handleInputChange}
                    className={
                      validation.name.message
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  <span className="error" role="alert">
                    {validation.email.message}
                  </span>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Group controlId="project_details">
              <Form.Label>Tell us about your project*</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                onChange={this.handleInputChange}
                className={
                  validation.name.message ? "text-input error" : "text-input"
                }
              />
              <span className="error" role="alert">
                {validation.project_details.message}
              </span>
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={this.handleInputChange}
              />
            </Form.Group>
          </div>

          <div style={{ borderTop: "5px solid #f8f8f8", padding: "16px 0px" }}>
            <h3>Suggest a good time for the call</h3>
            <Form.Row>
              <Form.Group controlId="dt1" as={Col} md={6}>
                <Form.Label>Click to suggest 1st date</Form.Label>
                <DatePicker
                  selected={this.state.startDate1}
                  placeholderText="Click to select a date"
                  onChange={this.handleDateChange1}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="do MMMM yyyy - h:mm aa"
                  timeCaption="time"
                  minTime={setHours(setMinutes(new Date(), 0), 6)}
                  maxTime={setHours(setMinutes(new Date(), 30), 23)}
                  minDate={subDays(new Date(), 0)}
                  defaultValue={null}
                />
              </Form.Group>
              <Form.Group controlId="dt2" as={Col} md={6}>
                <Form.Label>Click to suggest 2nd date</Form.Label>
                <DatePicker
                  selected={this.state.startDate2}
                  onChange={this.handleDateChange2}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="do MMMM yyyy - h:mm aa"
                  timeCaption="time"
                  placeholderText="Click to select a date"
                />
              </Form.Group>
              <Form.Group controlId="dt3" as={Col} md={6}>
                <Form.Label>Click to suggest 3rd date</Form.Label>
                <DatePicker
                  selected={this.state.startDate3}
                  onChange={this.handleDateChange3}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="do MMMM yyyy - h:mm aa"
                  timeCaption="time"
                  placeholderText="Click to select a date"
                />
              </Form.Group>
            </Form.Row>
          </div>

          <Form.Row>
            <Form.Group controlId="sripeElement" as={Col} sm={12}>
              <button className="paymentButton">
                {this.state.submitting ? (
                  <FontAwesome
                    className="super-crazy-colors"
                    name="spinner"
                    spin
                    style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
                  />
                ) : (
                  "Book Now"
                )}
              </button>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    );
  }
}

export class ChemistrySessionForm extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <CardForm
        consultant={this.props.consultant}
        handleResult={this.handleResult}
        handleFormChange={this.props.handleFormChange}
      />
    );
  }
}
