import React from "react";
import Img from "gatsby-image";
import Avatar from "../Avatar/Avatar";
const ProfileInfoSmallBooking = (props) => {
  const consultant = props.consultant;
  return (
    <div>
      <div
        className=""
        style={{
          textAlign: "center",
          margin: "0px auto",
          border: "0px",
          position: "relative",
        }}
      >
        <Avatar src={consultant.consultantImage.fixed} />
        <h5 className="profile_name">{consultant.consultantName}</h5>
        Hourly Rate: £{consultant.hourlyRate}
      </div>
    </div>
  );
};

export default ProfileInfoSmallBooking;
